<template>
  <vs-card id="add-user">
    <div slot="header">
      <h3 v-if="isEditing">
        {{ lang.users.editUser.title[languageSelected] }}
      </h3>
      <h3 v-else>{{ lang.users.createUser.title[languageSelected] }}</h3>
    </div>
    <vs-row>
      <vs-col :vs-w="!useStaffManagement ? '8' : '12'">
        <add-user-form></add-user-form>
      </vs-col>
      <vs-col vs-w="4" v-if="!useStaffManagement">
        <role-description></role-description>
      </vs-col>
    </vs-row>
  </vs-card>
</template>

<script>
import RoleDescription from './components/RoleDescription'
import AddUserForm from './components/AddUserForm'
import { mapState } from 'vuex'

export default {
  name: 'AddUser',
  components: { AddUserForm, RoleDescription },
  computed: {
    ...mapState(['lang', 'languageSelected', 'useStaffManagement']),
    isEditing() {
      return !!this.$route.params.userId
    }
  }
}
</script>

<style lang="scss" scoped>
#add-user {
  .add-user-container {
    margin: 30px auto;
    .vs-con-input-label {
      width: 100%;
    }
    .roles {
      li {
        padding: 5px 0;
      }
    }
  }
  .error-message {
    padding: 2px 4px;
    display: block;
    height: 19px;
    color: rgba(var(--vs-danger), 1);
    font-size: 0.65rem;
  }
}
</style>
