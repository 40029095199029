<template>
  <vs-card>
    <vs-list>
      <vs-list-header
        :title="supervisor.name[languageSelected]"
      ></vs-list-header>
      <vs-list-item
        v-for="(langItem, indexSupervisor) in lang.users.roles.supervisor"
        :key="`supervisor_${indexSupervisor}`"
        :subtitle="langItem[languageSelected]"
      ></vs-list-item>

      <vs-list-header :title="editor.name[languageSelected]"></vs-list-header>
      <vs-list-item
        v-for="(langItem, indexEditor) in lang.users.roles.editor"
        :key="`editor_${indexEditor}`"
        :subtitle="langItem[languageSelected]"
      ></vs-list-item>

      <vs-list-header :title="agent.name[languageSelected]"></vs-list-header>
      <vs-list-item
        v-for="(langItem, indexAgent) in lang.users.roles.agent"
        :key="`agent_${indexAgent}`"
        :subtitle="langItem[languageSelected]"
      ></vs-list-item>

      <vs-list-header
        :title="rolesManager.name[languageSelected]"
      ></vs-list-header>
      <vs-list-item
        v-for="(langItem, indexrolesManager) in lang.users.roles.rolesManager"
        :key="`manager_${indexrolesManager}`"
        :subtitle="langItem[languageSelected]"
      ></vs-list-item>
    </vs-list>
  </vs-card>
</template>

<script>
import { USER_ROLES } from '../../../../models/Roles/UserRoles'
import roles from '../../../../models/Roles'
import { mapState } from 'vuex'

export default {
  name: 'RoleDescription',
  computed: {
    ...mapState(['lang', 'languageSelected']),
    supervisor() {
      return roles[USER_ROLES.SUPERVISOR]
    },
    editor() {
      return roles[USER_ROLES.EDITOR]
    },
    agent() {
      return roles[USER_ROLES.AGENT]
    },
    rolesManager() {
      return roles[USER_ROLES.ROLES_MANAGER]
    }
  }
}
</script>
