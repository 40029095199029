<template>
  <vs-card>
    <form @submit="onSubmit" class="add-user-container" v-if="loaded">
      <form-group
        :label="lang.botMaker.addBot.manageUsers.table.name[languageSelected]"
      >
        <vs-input
          class="add-user-input"
          name="name"
          id="name"
          type="text"
          v-model="user.name"
          :danger="validation.name"
          :danger-text="lang.users.createUser.form.error.name[languageSelected]"
        />
      </form-group>

      <form-group
        :label="lang.botMaker.addBot.manageUsers.table.email[languageSelected]"
      >
        <vs-input
          class="add-user-input"
          name="email"
          type="text"
          :disabled="isEditing"
          v-model="user.email"
          :danger="validation.email"
          :danger-text="
            lang.users.createUser.form.error.email[languageSelected]
          "
        />
      </form-group>

      <form-group
        :label="
          lang.botMaker.addBot.manageUsers.table.username[languageSelected]
        "
      >
        <vs-input
          class="add-user-input"
          name="username"
          id="username"
          type="text"
          v-model="user.username"
          :danger="validation.username"
          :danger-text="
            lang.users.createUser.form.error.username[languageSelected]
          "
        />
      </form-group>

      <form-group
        :label="
          lang.botMaker.addBot.manageUsers.table.password[languageSelected]
        "
        v-if="!isEditing && showPass"
      >
        <vs-input
          class="add-user-input"
          name="password"
          type="password"
          v-model="user.password"
          :danger="validation.password"
          :danger-text="
            lang.users.createUser.form.error.password[languageSelected]
          "
        />
      </form-group>
      <form-group
        :label="lang.users.editUser.changePassword[languageSelected]"
        v-if="isEditing"
      >
        <vx-slider
          color="warning"
          vs-icon-off="lock"
          vs-icon-on="lock_open"
          v-model="user.changePassword"
          class="mb-4 mt-0"
        />
        <vs-input
          class="add-user-input mb-4"
          name="password"
          type="password"
          :label="lang.users.editUser.newPassword[languageSelected]"
          v-model="user.newPassword1"
          v-if="user.changePassword"
          :danger="validation.newPassword1"
          :danger-text="
            lang.users.createUser.form.error.password[languageSelected]
          "
        />
        <vs-input
          class="add-user-input"
          name="password"
          type="password"
          :label="lang.users.editUser.repeatPassword[languageSelected]"
          v-model="user.newPassword2"
          v-if="user.changePassword"
          :danger="validation.newPassword2"
          :danger-text="
            lang.users.createUser.form.error.password[languageSelected]
          "
        />
      </form-group>

      <form-group
        :label="lang.users.editUser.passwordExpiration.title[languageSelected]"
        v-if="isEditing"
      >
        <span v-if="user.passwordExpiration" class="text-base">
          {{ user.passwordExpiration | moment('from', 'now') }}
        </span>
        <span v-else class="text-base text-dark">
          {{ lang.users.editUser.passwordExpiration.never[languageSelected] }}
        </span>
        <vs-button
          type="flat"
          class="ml-2"
          v-if="
            user.id !== session.user.id &&
              (!user.passwordExpiration ||
                new Date(user.passwordExpiration) > new Date())
          "
          @click.stop.prevent="expireNow"
          >{{
            lang.users.editUser.passwordExpiration.button[languageSelected]
          }}</vs-button
        >
      </form-group>

      <form-group
        :label="lang.botMaker.addBot.manageUsers.table.role[languageSelected]"
        v-if="!useStaffManagement && !isSuperadmin"
      >
        <ul class="roles">
          <li v-if="session.user.roles.canCreate.supervisor">
            <vs-checkbox
              :disabled="!canEditRoles"
              v-model="user.roles.ids"
              :vs-value="rolesAvailable[userRoles.SUPERVISOR].id"
              name="supervisor"
            >
              {{ rolesAvailable[userRoles.SUPERVISOR].name[languageSelected] }}
            </vs-checkbox>
          </li>
          <li v-if="session.user.roles.canCreate.editor">
            <vs-checkbox
              :disabled="!canEditRoles"
              v-model="user.roles.ids"
              :vs-value="rolesAvailable[userRoles.EDITOR].id"
              name="editor"
              >{{
                rolesAvailable[userRoles.EDITOR].name[languageSelected]
              }}</vs-checkbox
            >
          </li>
          <li v-if="session.user.roles.canCreate.agent">
            <vs-checkbox
              :disabled="!canEditRoles"
              v-model="user.roles.ids"
              :vs-value="rolesAvailable[userRoles.AGENT].id"
              name="agent"
              >{{
                rolesAvailable[userRoles.AGENT].name[languageSelected]
              }}</vs-checkbox
            >
          </li>
          <li v-if="session.user.roles.canCreate.rolesManager">
            <vs-checkbox
              :disabled="!canEditRoles"
              v-model="user.roles.ids"
              :vs-value="rolesAvailable[userRoles.ROLES_MANAGER].id"
              name="rolesManager"
            >
              {{
                rolesAvailable[userRoles.ROLES_MANAGER].name[languageSelected]
              }}
            </vs-checkbox>
          </li>
        </ul>
        <p v-show="validation.roles" class="error-message">
          {{ lang.users.createUser.form.error.roles[languageSelected] }}
        </p>
      </form-group>

      <form-group
        :label="lang.botMaker.addBot.manageUsers.table.role[languageSelected]"
        v-if="useStaffManagement"
      >
        <multiselect
          :disabled="!canEditRoles"
          v-model="multiselectModel"
          :placeholder="
            lang.users.roles.multiselectPlaceholder[languageSelected]
          "
          tagPlaceholder=""
          :show-labels="false"
          label="name"
          track-by="_id"
          :options="multiselectOptions"
          :multiple="true"
          :taggable="false"
        />
      </form-group>

      <vs-row>
        <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end">
          <vs-button
            color="danger"
            type="flat"
            class="mr-4"
            @click.prevent="$router.push('/users')"
          >
            {{ lang.users.createUser.btn.cancel[languageSelected] }}
          </vs-button>
          <vs-button :disabled="useStaffManagement && multiselectModel.length === 0">
            <span v-if="isEditing">{{
              lang.users.createUser.btn.update[languageSelected]
            }}</span>
            <span v-else>{{
              lang.users.createUser.btn.create[languageSelected]
            }}</span>
          </vs-button>
        </vs-col>
      </vs-row>
    </form>
  </vs-card>
</template>

<script>
import Roles from '../../../../models/Roles'
import { USER_ROLES } from '../../../../models/Roles/UserRoles'
import { mapState } from 'vuex'

import BotMakerService from '../../../../services/botMaker.service'
import StaffManagementService from '../../../../services/staffManagement.service'

import { User } from '../../../../models/User'
import FormGroup from '../../bot-maker/add-bot/Components/FormGroup'

const ERRORS = {
  EXISTING_USER: 'email already in use'
}

export default {
  name: 'AddUserForm',
  components: {
    FormGroup,
    Multiselect: () => import('vue-multiselect')
  },
  data() {
    return {
      loaded: false,
      isEditing: false,
      user: {},
      validation: {
        name: false,
        username: false,
        email: false,
        password: false,
        roles: false,
        newPassword1: false,
        newPassword2: false
      },
      login: this.$store.state.loginMethod,
      rolesAvailable: Roles,
      userRoles: USER_ROLES,
      canEditRoles: true,

      multiselectModel: [],
      multiselectOptions: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement']),
    roles() {
      const userRole = this._.find(Roles, r => {
        return r.defaultName === this.session.roles[0]
      })
      const rolesTmp = []
      userRole.canCreate.forEach(r => {
        rolesTmp.push(Roles[r])
      })

      return rolesTmp
    },
    isSuperadmin() {
      return this.user.roles.ids.indexOf(this.userRoles.SUPER_ADMIN) > -1
    },
    showPass() {
      return this.$store.state.loginMethod != 'ldap'
    }
  },
  methods: {
    expireNow() {
      this.$vs.loading()
      BotMakerService.expireUserPassword(this.user.id)
        .then(response => {
          console.log('exito', response)
          this.user.passwordExpiration = response.data._changePasswordAt
        })
        .catch(error => {
          this.$log.error(error)
          this.displayErrorMessage()
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    onSubmit(event) {
      console.log('event', event)
      this.validation.name = !this.user.isNameValid()
      this.validation.email = !this.user.isEmailValid()
      if (!this.isEditing) {
        this.validation.newPassword1 = false
        this.validation.newPassword2 = false
        this.validation.password = !this.user.isPasswordValid()
      } else {
        this.validation.password = false
        this.validation.newPassword1 = !this.user.isNewPassword1Valid()
        this.validation.newPassword2 = !this.user.isNewPassword2Valid()
      }
      this.validation.roles = this.user.roles.ids.length === 0

      event.preventDefault()

      if (
        !this.user.isUserFormValid() ||
        (this.validation.roles && !this.useStaffManagement)
      ) {
        const errorTitle = this.lang.users.createUser.form.error.generic.title[
          this.languageSelected
        ]
        this.$notify.error(errorTitle)
        return true
      }

      if (this.isEditing) {
        this.updateUser()
      } else {
        this.createUser()
      }
    },
    async updateUser() {
      this.$log.info(this.user)
      this.$vs.loading()
      const userToSave = this.user.getUserToSave()

      if (this.useStaffManagement) {
        userToSave.staffManagementRoles = (this.getStaffRolesToPush()).map(item => item._id)
      }
      BotMakerService.updateUser(this.user.id, userToSave)
        .then(() => {
          this.resetErrors()
          this.$router.push('/users')
        })

        .catch(error => {
          this.$log.error(error)
          this.displayErrorMessage()
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    createUser() {
      this.$log.info(this.user)
      this.$vs.loading()
      const userToSave = this.user.getUserToSave()
      if (this.useStaffManagement) {
        userToSave.staffManagementRoles = (this.getStaffRolesToPush()).map(item => item._id)
      }
      BotMakerService.createUser(userToSave)
        .then(async response => {
          this.user.id = response.data._id
          this.resetErrors()
          this.$router.push('/users')
        })
        .catch(error => {
          this.$log.error(error)
          if (
            error.response.status === 400 &&
            error.response.data.error === ERRORS.EXISTING_USER
          ) {
            this.$vs.notify({
              title: this.lang.users.createUser.form.error.emailAlreadyInUse
                .title[this.languageSelected],
              text: this.lang.users.createUser.form.error.emailAlreadyInUse
                .text[this.languageSelected],
              color: 'danger'
            })
          }
        })
        .finally(() => {
          this.$vs.loading.close()
        })
    },
    resetErrors() {
      this.validations = {
        name: false,
        email: false,
        username: false,
        password: false,
        roles: false
      }
    },
    displayErrorMessage() {
      this.$vs.notify({
        title: this.lang.botMaker.errorTitle[this.languageSelected],
        text: this.lang.botMaker.error[this.languageSelected]
      })
    },
    getStaffRolesToPush() {
      return this.multiselectModel
    },
    getStaffRolesToPull() {
      return this.multiselectOptions.filter(
        item =>
          item.check &&
          !this.multiselectModel.some(element => element._id === item._id)
      )
    }
  },
  async mounted() {
    const userId = this.$route.params.userId
    if (userId === this.session.user.id) {
      this.canEditRoles = false
    }
    if (this.useStaffManagement) {
      try {
        let userRoles = []
        if (userId) {
          userRoles = (await StaffManagementService.getStaffRolesByUser(userId))
            .data
        }
        const roles = (await StaffManagementService.getStaffRoles()).data
        this.multiselectOptions = roles.map(item => ({
          _id: item._id,
          name: item.name,
          groupId: item.groupId,
          passwordExpiration: item.passwordExpiration,
          usersLength: item.users.length,
          permissionsLength: item.permissions.length,
          check: userRoles.some(r => r._id === item._id)
        }))
        this.multiselectModel = this.multiselectOptions.filter(
          item => item.check
        )
      } catch (ex) {
        this.$log.error(ex)
        this.$vs.notify({
          title: this.lang.botMaker.errorTitle[this.languageSelected],
          text: this.lang.botMaker.error[this.languageSelected],
          color: 'danger'
        })
      }
    }
    if (userId) {
      this.isEditing = true
      this.$vs.loading()
      BotMakerService.getUser(userId)
        .then(response => {
          let userTmp = response.data
          this.user = new User(
            userTmp.name,
            userTmp.email,
            userTmp.password,
            userTmp.roles,
            userTmp.company,
            userTmp._id,
            userTmp._changePasswordAt,
            userTmp.user
          )
        })
        .catch(error => {
          this.$log.error(error)
          this.$vs.notify({
            title: this.lang.botMaker.errorTitle[this.languageSelected],
            text: this.lang.botMaker.error[this.languageSelected],
            color: 'danger'
          })
        })
        .finally(() => {
          this.loaded = true
          this.$vs.loading.close()
        })
    } else {
      this.user = new User()
      this.loaded = true
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.add-user-container {
  margin: 30px auto;
  .vs-con-input-label {
    width: 100%;
  }
  .roles {
    li {
      padding: 5px 0;
    }
  }
}
.error-message {
  padding: 2px 4px;
  display: block;
  height: 19px;
  color: rgba(var(--vs-danger), 1);
  font-size: 0.65rem;
}
</style>
